<template>
  <v-app>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success'
        }"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
         <div class="mb-8 mt-8 text-center">
          <h1>Assign Roles</h1>
        </div>

        <v-form ref="roles" v-model="valid">
          <v-row>
            <v-col >
              <TextField v-model="formData.Name" :label="'Name'" />
            </v-col>

              <v-col  dense>
              <SelectField
                :items="Roles"
                v-model="formData.RoleId"
                :label="'Roles'"
                :itemText="'name'"
                :itemValue="'id'"
              />
            </v-col>
             <v-col cols="12" class="d-flex justify-end">

                      <Button
                      :label="'Submit'"
                      :btnType="'Submit'"
                      @onClick="onSave"
                      :disabled="!valid"
                      :isLoading="isLoading"
                      class="mr-4"
                    />

                    <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />

                  </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
// import { stateService, holidayService } from '@/services'
export default {
  components: {
    TextField,
    Button,
    SelectField
  },
  data () {
    return {

      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      isLoading: false,

      formData: {

        RoleId: '',

        Name: ''
      },
      Roles: [{ id: 2, name: 'LCE' }, { id: 3, name: 'LCM' }, { id: 4, name: 'Supervisor' }, { id: 5, name: 'Superintendent' }, { id: 6, name: 'HRCLU' }, { id: 7, name: 'Finance' }, { id: 8, name: 'SCM' }]
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    onSave () {
      this.isLoading = true
      // holidayService.createHoliday(this.formData).then(() => {
      //   this.showAlertMessage('Holiday creation successful', 'success')
      // }).catch((error) => {
      //   console.log(error)
      //   this.showAlertMessage('Holiday creation failed', 'error')
      // }).finally(() => {
      //   this.isLoading = false
      //   this.onReset()
      // })
    },
    onReset () {
      this.$refs.roles.reset()
    }
  },
  mounted () {
  //   stateService.getAllStates().then(result => {
  //     this.states = result.data.items
  //   }).catch(error => {
  //     console.log(error)
  //   })
  }
}
</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.v-input--selection-controls{
  margin: 0;
  padding: 0;
}
</style>
